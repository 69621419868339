/** @jsx jsx */
import { jsx, Grid, Flex, Text, Box, Heading, Button, Spinner } from 'theme-ui'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Formik, Form, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { pick, compose, none, isNil, values } from 'ramda'
import { useLocation } from '@reach/router'
import qs from 'query-string'
import { useLocalStorage } from 'react-use'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { useCallback } from 'react'
import { navigate, Link } from 'gatsby'

import { useMutation } from '@apollo/client'
import { CREATE_GUEST_BOOKING, CREATE_GUEST_PAYMENT } from 'queries/bookings'

import { orderSchema } from 'schemas/order-schema'
import { SEO } from 'components'

import TextField from 'components/forms/text-field'
import { CustomErrorField } from 'components/forms/error-field'
import BookingInfo from 'components/booking/booking-info'
import CountrySelectField from 'components/forms/country-select'
import CheckboxField from 'components/forms/checkbox-field'

const getHasErrors = compose(none(isNil), values)

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: `"SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
      fontSize: '18px',
      letterSpacing: '-0.01em',
      minHeight: 48,
      lineHeight: '48px',
      paddingTop: 0,
      paddingBottom: 0,
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

function PaymentForm() {
  const stripe = useStripe()
  const elements = useElements()

  const location = useLocation()
  const query = qs.parse(location.search)

  const resourceData = pick(['start', 'end', 'id'], query)

  const [bookingToken, setToken, removeToken] = useLocalStorage('booking-token')

  const handleSuccess = useCallback((res) => {
    const token = res?.createGuestBooking?.bookingToken

    if (token) {
      setToken(bookingToken)
      console.log(token)
    }
  }, [])

  const [createBooking] = useMutation(CREATE_GUEST_BOOKING, {
    onCompleted: handleSuccess,
  })
  const [completeBooking] = useMutation(CREATE_GUEST_PAYMENT)

  const handleSubmit = useCallback(
    async (values) => {
      const cardElement = elements.getElement(CardNumberElement)
      const payload = await stripe.createToken(cardElement)

      if (payload.error) {
        console.log('[error]', payload)
      } else {
        console.log('[PaymentMethod]', payload)

        const bookingValues = pick(
          [
            'organisationId',
            'firstName',
            'lastName',
            'email',
            'phone',
            'resources',
            'extras',
          ],
          values
        )

        try {
          const res = await createBooking({
            variables: {
              booking: bookingValues,
            },
          })

          const booking = res?.data?.createGuestBooking

          const bookingId = booking?.id
          const bookingToken = booking?.bookingToken

          await completeBooking({
            variables: {
              id: bookingId,
              token: bookingToken,
              booking: {
                stripeToken: payload.token.id,
                ...pick(
                  ['address1', 'city', 'county', 'postCode', 'country'],
                  values
                ),
              },
            },
          })

          navigate(`/booking/success?token=${bookingToken}`)
        } catch (err) {
          console.log({ err })
        }
      }
    },
    [stripe, elements, resourceData]
  )

  return (
    <>
      <SEO title="Booking" />

      <Formik
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
        validationSchema={orderSchema}
        initialValues={{
          organisationId: process.env.GATSBY_ORGANISATION_ID,
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          address1: '',
          city: '',
          county: '',
          postCode: '',
          country: 'United Kingdom',
          resources: [{ ...resourceData }],
          extras: [],
          cardNumber: '',
          cardExpiry: '',
          cardCvc: '',
          agreedToTerms: false,
          // ...testValues,
        }}
      >
        <Form>
          <Grid
            columns="2fr 1fr"
            sx={{
              marginX: 'auto',
              py: ['xl', 'xxl', 'xxxl'],
              px: ['m', 'l'],
              maxWidth: 960,
            }}
          >
            <Box>
              <Heading variant="heading.m" sx={{ marginBottom: 'l' }}>
                Book your room at The Station Inn
              </Heading>
              <RenderFormFields />
            </Box>
            <BookingInfo {...query} />
          </Grid>
        </Form>
      </Formik>
    </>
  )
}

function RenderFormFields() {
  const {
    isSubmitting,
    isValid,
    setFieldError,
    setErrors,
    errors,
    values,
  } = useFormikContext()

  console.log(values)

  useEffect(() => {
    const hasErrors = getHasErrors(errors)
    if (!hasErrors) {
      setErrors({})
    }
  }, [errors])

  const handleField = useCallback(
    (name) => (ev) => {
      if (ev?.error?.message) {
        setFieldError(name, ev.error.message, true)
      } else {
        setFieldError(name, null, true)
      }
    },
    [errors]
  )

  const handleBlur = useCallback(
    (name) => () => {
      console.log('touched', name)
      // setFieldTouched(name)
    },
    [errors]
  )

  const hasAgreedToTerms = values?.agreedToTerms

  return (
    <Box sx={{ cursor: isSubmitting && 'wait' }}>
      <Grid
        gap="xl"
        sx={{ maxWidth: 540, pointerEvents: isSubmitting && 'none' }}
      >
        <Grid gap="s">
          <Heading variant="stnd.xs">Personal Information</Heading>
          <Grid columns={2} gap="s">
            <TextField name="firstName" placeholder="First name" />
            <TextField name="lastName" placeholder="Last name" />
          </Grid>
          <Grid columns={2} gap="s">
            <TextField name="email" placeholder="Email address" />
            <TextField name="phone" placeholder="Phone number" />
          </Grid>
        </Grid>

        <Grid gap="s">
          <Heading variant="stnd.xs">Billing Information</Heading>
          <TextField name="address1" placeholder="Address" />
          <Grid columns={2} gap="s">
            <TextField name="city" placeholder="City" />
            <TextField name="county" placeholder="County" />
          </Grid>
          <Grid columns={2} gap="s">
            <TextField name="postCode" placeholder="Post code" />
            <CountrySelectField name="country" placeholder="Country" />
          </Grid>
        </Grid>
        <Grid gap="s">
          <Heading variant="stnd.xs">Payment Details</Heading>
          <Grid gap="xs">
            <CardNumberElement
              className="input"
              name="cardNumber"
              placeholder="Card number"
              options={ELEMENT_OPTIONS}
              onChange={handleField('cardNumber')}
              onBlur={handleBlur('cardNumber')}
            />
            <CustomErrorField name="cardNumber" />
          </Grid>

          <Grid columns={2} gap="s">
            <Grid gap="xs" sx={{ alignSelf: 'flex-start' }}>
              <CardExpiryElement
                className="input"
                name="cardExpiry"
                placeholder="Expiry"
                options={ELEMENT_OPTIONS}
                onChange={handleField('cardExpiry')}
                onBlur={handleBlur('cardExpiry')}
              />
              <CustomErrorField name="cardExpiry" />
            </Grid>

            <Grid gap="xs" sx={{ alignSelf: 'flex-start' }}>
              <CardCvcElement
                className="input"
                name="cardCvc"
                placeholder="CVC"
                options={ELEMENT_OPTIONS}
                onChange={handleField('cardCvc')}
                onBlur={handleBlur('cardCvc')}
              />
              <CustomErrorField name="cardCvc" />
            </Grid>
          </Grid>
        </Grid>
        <Grid gap="s">
          <Flex sx={{ alignItems: 'center' }}>
            <CheckboxField name="agreedToTerms" label="Do you agree to our" />

            <Text
              as="a"
              href="/terms-and-conditions"
              sx={{
                fontSize: 'xxs',
                marginLeft: '0.5ch',
                color: 'black.100',
                textUnderlineOffset: 3,
              }}
              target="_blank"
            >
              Terms and Conditions
            </Text>
            <Text sx={{ fontSize: 'xxs' }}>?</Text>
          </Flex>

          {isSubmitting ? (
            <Button
              sx={{
                width: '100% !important',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              disabled={!isValid}
            >
              <Spinner size={13} sx={{ marginRight: 'xs', display: 'block' }} />
              <span>Securing booking</span>
            </Button>
          ) : (
            <Button
              sx={{ width: '100% !important' }}
              type="submit"
              disabled={!isValid || !hasAgreedToTerms}
            >
              <span>Pay for booking</span>
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY)

export default function Booking() {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm />
    </Elements>
  )
}
