/** @jsx jsx */
import { jsx, Box, Flex, Text } from 'theme-ui'

import { daysDifference } from 'utils/dates'
import { toPounds } from 'utils'

import Subhead from './subhead'

export default function ResourceCost({ resource }) {
  return (
    <Box>
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Subhead>{resource.title}</Subhead>
      </Flex>

      <Flex sx={{ justifyContent: 'space-between' }}>
        <Text variant="stnd.xs">
          {daysDifference([resource.start, resource.end])} nights ×{' '}
          {toPounds(resource.blockCost / 100)}
        </Text>
        <Text variant="stnd.xs">{toPounds(resource.totalCost / 100)}</Text>
      </Flex>
    </Box>
  )
}
