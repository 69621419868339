/** @jsx jsx */
import { jsx, Flex, Box, Heading, Grid, Text } from 'theme-ui'
import GatsbyImage from 'gatsby-image'
import {
  compose,
  find,
  propEq,
  prop,
  path,
  curry,
  map,
  divide,
  findIndex,
} from 'ramda'
import { useCallback, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useFormikContext, FieldArray } from 'formik'
import { useQuery } from '@apollo/client'

import BookingDates from './booking-dates'
import ResourceCost from './resource-cost'
import Subhead from './subhead'
import ExtraCheckboxField from 'components/forms/extra-checkbox-field'

import useBookingCost from 'hooks/use-booking-cost'

import { GET_ROOM_EXTRAS } from 'queries/rooms'

import { toPounds } from 'utils'

export const ROOM_QUERY = graphql`
  query {
    inn: file(relativePath: { eq: "inn-payment.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1680) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rooms: allContentfulRoom {
      edges {
        node {
          ...RoomFragment
          roomType {
            id
            shortName
          }
        }
      }
    }
  }
`

const findRoomByIdentifier = curry((identifier, data) =>
  compose(
    find(propEq('identifier', identifier)),
    map(prop('node')),
    path(['rooms', 'edges'])
  )(data)
)

export default function BookingInfo(props) {
  const { identifier, start, end, id } = props

  const { setFieldValue, values } = useFormikContext()

  useEffect(() => {
    // utle.log(values)
  }, [values])

  const roomQuery = useStaticQuery(ROOM_QUERY)

  const room = findRoomByIdentifier(identifier, roomQuery)

  const { data: costData } = useBookingCost({
    resourceId: id,
    start,
    end,
    extras: values?.extras ?? [],
  })

  const { data: roomData } = useQuery(GET_ROOM_EXTRAS, {
    variables: { identifier },
    skip: !identifier,
  })

  const extras = roomData?.room?.extras

  const resourcesCosts = costData?.calculateItemisedCost?.resources ?? []
  const extrasCosts = costData?.calculateItemisedCost?.extras ?? []

  const totalCost = divide(costData?.calculateItemisedCost?.totalCost ?? 0, 100)

  const addExtra = useCallback(
    (extraId) => {
      setFieldValue('extras', [{ id: extraId, start, end }])
    },
    [setFieldValue, values, start, end]
  )

  return (
    <Grid
      sx={{
        position: 'sticky',
        top: 0,
        right: 0,
        paddingTop: 40,
        marginTop: -40,
        alignSelf: 'flex-start',
      }}
      gap="s"
    >
      <Box
        sx={{
          borderRadius: 10,
          overflow: 'hidden',
          border: 'solid 1px',
          borderColor: 'black.10',
          paddingBottom: 'm',
        }}
      >
        {room && (
          <Box>
            <GatsbyImage
              fluid={roomQuery.inn.childImageSharp.fluid}
              style={{ display: 'block' }}
            />

            <Box sx={{ paddingX: 's', paddingTop: 'm' }}>
              <Subhead>Room</Subhead>
              <Heading variant="heading.m" sx={{ marginBottom: 's' }}>
                {room.name}
              </Heading>
              <Box sx={{ marginBottom: 's' }}>
                <BookingDates start={start} end={end} />
              </Box>
              <Box sx={{ marginBottom: 's' }}>
                <Grid gap="s">
                  {resourcesCosts.map((extra, key) => (
                    <ResourceCost resource={extra} key={key} />
                  ))}

                  {extrasCosts.map((extra, key) => (
                    <ResourceCost resource={extra} key={key} />
                  ))}
                </Grid>
              </Box>

              <Box>
                <Subhead>Total</Subhead>
                <Text>{toPounds(totalCost)}</Text>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {extras && (
        <BookingExtras extras={extras} defaultValue={{ start, end }} />
      )}
    </Grid>
  )
}

function BookingExtras(props) {
  const { extras, defaultValue } = props

  const { values } = useFormikContext()

  console.log(extras)

  return (
    <Box
      sx={{
        borderRadius: 10,
        overflow: 'hidden',
        border: 'solid 1px',
        borderColor: 'black.10',
        paddingBottom: 'm',
      }}
    >
      <Box sx={{ paddingX: 's', paddingTop: 'm' }}>
        <Subhead>Extras</Subhead>

        <Grid gap={2}>
          <FieldArray name="extras">
            {({ insert, remove, push, replace }) =>
              extras.map((extra, index) => {
                return (
                  <Flex key={index} sx={{ justifyContent: 'space-between' }}>
                    <ExtraCheckboxField
                      key={extra.id}
                      name={`extras.${index}`}
                      onChange={(ev) => {
                        if (ev.target.checked) {
                          push({ id: extra.id, ...defaultValue })
                        } else {
                          const idx = findIndex(
                            propEq(extra.id, 'id'),
                            values.extras
                          )

                          remove(idx)
                        }
                      }}
                      payload={extra}
                      label={extra.title}
                    />
                    <Text sx={{ fontSize: 'xxs' }}>
                      {toPounds(extra.dailyCost / 100)} / Night
                    </Text>
                  </Flex>
                )
              })
            }
          </FieldArray>
        </Grid>
      </Box>
    </Box>
  )
}
